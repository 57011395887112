<template>
  <div class="pb-3">
    <v-layout row>
      <v-flex xs3>
        <v-text-field
          v-model="search"
          hide-details
          solo
          label="Search Video"
          append-icon="search"
          @input="searchInput"
        />
      </v-flex>
    </v-layout>

    <v-layout row justify-space-between>
      <v-flex xs6>
        <v-btn-toggle class="table-top-menu">
          <v-btn flat large :to="{ name: 'all_verification_videos' }">
            All
          </v-btn>

          <v-btn flat large :to="{ name: 'accepted_verification_videos' }">
            Accepted
          </v-btn>

          <v-btn flat large :to="{ name: 'declined_verification_videos' }">
            Declined
          </v-btn>
        </v-btn-toggle>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: "PostListTopNav",
  data: () => ({
    inputSearchPostTimer: null,
    search: null
  }),
  methods: {
    searchInput() {
      clearTimeout(this.inputSearchUserTimer)
      this.inputSearchUserTimer = setTimeout(() => {
        this.$bus.emit("user-search-input", this.search)
      }, 2000)
    }
  }
}
</script>

<style scoped>
.table-top-menu {
  width: 100%;
}

.table-top-menu .v-btn {
  height: 48px;
  flex-grow: 1;
}

.v-btn.v-btn--active {
  pointer-events: none;
}
.count-new-posts {
  position: absolute;
  right: 0;
  top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border: 2px solid #f44336;
  border-radius: 50%;
  color: #f44336;
  font-size: 12px;
  font-weight: bold;
  transform: translateY(-50%);
}
</style>
